


































































import WidgetMixins from "../../../../mixins/WidgetMixins";
import Component, { mixins } from "vue-class-component";
import { loadWidget } from "@/utils/helpers";
import { Emit, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IOrders, IStore, IUser, PayloadState } from "@/types/types";

const productsModule = namespace("orders");
const authModule = namespace("auth");
@Component({
  name: "PackageXtras",
  components: {
    SkeletonPreloader: loadWidget("widgets/SkeletonPreloader")
  }
})
export default class PackageXtras extends mixins(WidgetMixins) {
  @productsModule.Getter("getOrderDetails") details!: IOrders;
  @authModule.Getter("getUserDetails") userDetails!: IUser & IStore;
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading

  comment: string = "";

  @Watch("details")
  onDetailsChange(payload: any) {
    console.log(payload);
  }

  @Emit("markAsCompleted")
  markOrderAsCompleted() {
    return {
      comment: this.comment,
      status: "COMPLETED"
    };
  }

  @Emit("actions")
  close(): PayloadState {
    return {
      idx: "details",
      state: false
    };
  }
}
